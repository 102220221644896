/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 80; /* Stay on top */
  top: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.733); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
}

.feedbackButton {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
  background-color: rgba(17, 17, 17, 0);
  outline: none;
  border: none;
}
/* When you mouse over the navigation links, change their color */
.feedbackButton:hover,
.sidebar a:hover {
  color: #a8a4a4;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 3px;
  right: 14px;
  font-size: 18px;
  margin-left: 50px;
  margin-top: 5px;
  outline: none;
  border: none;
  font-size: large;
  background-color: rgba(219, 215, 219, 0);
  color: rgba(255, 255, 255, 0.925);
}

.checkBoxItem {
  padding: 2px;
  align-content: left;
}

.links {
  position: absolute;
  right: 75px;
  bottom: 100px;
}

.filters {
  left: 5px;
  display: inline-block;
  text-align: left;
}

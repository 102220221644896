.searchBar {
  display: inline-block;
  text-align: left;
  margin-top: 5px;
  vertical-align: top;
}

#searchBarInput {
  height: 18px;
  vertical-align: top;
  border-radius: 4px;
}

.searchBar .searchBarButton {
  outline: none;
  border: none;
  font-size: medium;
  background-color: rgba(17, 17, 17, 0);
  color: rgb(19, 18, 18);
  border-radius: 4px;
  padding: 4px;
  vertical-align: top;
  margin-left: -23px;
}

ul.options {
  display: block;
  list-style: none;
  width: 158px;
  transition: width 0.3s;
  margin: auto;
  position: relative;
  right: 30px;
}

ul.options li {
  display: block;
  background: white;
  margin: 1px auto;
  padding: 1px;
  font-size: 12px;
  width: 100%;
  border-radius: 2px;
}
ul.options li:hover {
  font-weight: bold;
  color: #00b4cc;
  cursor: pointer;
  transition: 0.3s all;
}

ul.options li.option-active {
  background: whitesmoke;
  font-size: 12px;
  color: #00b4cc;
}
.no-options {
  color: white;
}

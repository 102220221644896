.leaflet-container {
  width: 100%;
  height: 100vh;
}

#map {
  height: 100%; /* 92%*/
  width: 100vw;
}
/*
@media (min-width: 768px) {
  #map {
    height: 100%;
    width: 100vw;
  }
}*/

.td {
  text-align: left;
  margin-top: 10px;
}

.tr {
  margin-top: 10px;
  margin-right: 15px;
}

.popUpTableRow:nth-child(even) {
  background-color: #dddddd;
}

.locateBtn {
  position: absolute;
  background-color: #e9e0e0;
  color: #0f0e0e;
  z-index: 999;
  top: 90px;
  right: 14px;

  border-radius: 6px;
  padding: 4px;
  padding-top: 4px;
  padding-bottom: 1px;

  font-size: large;
}

.infoBtn {
  position: absolute;
  background-color: #e9e0e0;
  color: #0f0e0e;
  z-index: 999;
  top: 130px;
  right: 14px;

  border-radius: 6px;
  border-color: rgb(131, 121, 121);
  padding: 4px;
  padding-top: 4px;
  padding-bottom: 1px;

  font-size: large;
}

.infoPopUp {
  font-size: 12px;
  width: 200px;
  background-color: rgba(212, 203, 203, 0.68);
  position: absolute;
  right: 8px;
  top: 0px;
  border-radius: 18px;
  border: 3px solid rgba(36, 33, 33, 0.475);
}
.infoPopUp > .popUpHeader {
  width: 100%;
  border-bottom: 0px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.infoPopUp > .popUpContent {
  width: 100%;
  padding: 10px 5px;
}
.infoPopUp > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.infoPopUp > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 30px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  z-index: 999;
}

.custom-icon {
}

/*.propertyBox {
  position: absolute;
  z-index: 10;
  background-color: rgba(228, 220, 228, 0.753);
  left: 10vw;
  top: 1vh;
  width: 150px;
  height: 45px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}
*/
/*
*[id^='propertyBox'] {
  z-index: 10;
  margin: 2px;
  float: left;
  background-color: rgba(228, 220, 228, 0.753);
  
  width: 150px;
  height: 45px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}*/

.propertyBoxGroup {
  position: absolute;
  z-index: 10;
  /* background-color: rgba(228, 220, 228, 0.753);*/
  right: 0;
  top: 36px;
  /*width: 150px;
  height: 45px;*/
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  width: 400px;
  height: 5vh;
}

.searchbar1 {
  position: absolute;
  z-index: 10;
  margin: 0px;
  right: 16px;
  top: 3px;

  font-size: 0.7em;

  height: 25px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}

.mapView {
  /* position: relative;*/
  z-index: 1;
}

.infobar {
  position: absolute;
  width: 200px;
  display: flex;
  flex-direction: row;

  z-index: 10;
  /* background-color: rgba(228, 220, 228, 0.753);*/
  left: 5px;
  top: 4px;
  /*width: 150px;
  height: 45px;*/
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}

.companyLogo {
  z-index: 10;
  margin: 0px;
  padding: 0px;
}

.info {
  z-index: 10;

  color: rgb(5, 5, 5);
  background-color: rgba(228, 220, 228, 0.233);
}

.header {
  position: absolute;
  font-size: 18px;
  z-index: 5;
  background-color: rgba(8, 8, 8, 0.589);
  right: 0vw;
  top: 0vh;
  width: 100%;
  height: 38px;

  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}
.openSideBarBtn {
  background-color: rgba(219, 215, 219, 0);
  color: rgba(255, 255, 255, 0.925);
  border-radius: 4px;
  padding: 6px;
  margin-left: 15px;
  margin-top: 0px;
  float: right;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: x-large;
}

.imageButton .imageButtonImage {
  float: right;
  height: 15px;
  width: 25px;
  padding: 0;
  outline: none;
  border: none;
  margin-top: 8px;
  margin-right: 10px;
}

.languages {
  margin-right: 60px;
}

.feedBackPopUp {
  height: 0; /* 100% Full-height */
  width: 350px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 180; /* Stay on top */
  top: 0;
  left: 180px;
  background-color: rgba(17, 17, 17, 0.767); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.feedbackFormCloseBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.cookieWindow {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}

.cityDropDown {
  position: absolute;
  min-width: 70px;
  top: 44px;
  left: 5px;

  margin: 0px;
  padding: 0%;

  font-size: small;
  z-index: 999;
}

.serviceLinks button {
  outline: none;
  border: none;
  font-size: small;
  padding: 4px;
  margin-right: 10px;
  background-color: rgba(219, 215, 219, 0);
  color: rgba(255, 255, 255, 0.925);
  font-size: x-large;
}

.serviceLinks {
  margin-left: 10px;
}
/*
.tileLayer {
  filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7) !important;
}
*/
/*
:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
    filter: var(--map-tiles-filter, none);
  }
}*/

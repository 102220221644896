.circle-wrap {
  margin: 0px auto;
  width: 100px;
  height: 100px;
  background: #fefcff;
  border-radius: 50%;
  border: 1px solid #cdcbd0;
  animation: drop-in ease-in-out 1s;
}
.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
}

.mask .fill {
  clip: rect(0px, 50px, 100px, 0px);
  background-color: #227ded;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 100px, 100px, 50px);
}

.mask.full,
.circle .fill {
  /*animation: slide-in ease-in-out 3s;*/
  /* transform: rotate(90deg);*/
}

@keyframes slide-in {
  from {
    opacity: 0.45;
  }
  to {
    opacity: 1;
  }
}

@keyframes drop-in {
  from {
    transform: scale(0.85);
    opacity: 0.45;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
/*
@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}*/
.circle-wrap .inside-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #d2eaf1;
  /*line-height: 320%;*/

  text-align: center;

  margin-top: 10px;
  margin-left: 10px;
  color: #1e51dc;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 1.7em;
}

.inside-text {
  position: relative;
  top: 15%;
  z-index: 999;
  text-align: center;
}
/*

.circle-wrap {
  margin: 0px auto;
  width: 150px;
  height: 150px;
  background: #fefcff;
  border-radius: 50%;
  border: 1px solid #cdcbd0;
}
.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #227ded;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(135deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
.circle-wrap .inside-circle {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background: #d2eaf1;
  line-height: 120px;
  text-align: center;
  margin-top: 14px;
  margin-left: 14px;
  color: #1e51dc;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
}



*/

.feedBackFormHeader,
.feedBackFormSmiley,
.feedBackFormFreeText {
  border-bottom: 0px solid gray;
}

.feedBackLevelItem {
  padding: 0px;
  margin: 10px;
  border-radius: 12px;
  cursor: pointer;
}
.smileyFaceRow {
  margin: auto;
  padding: 20px;
}
.feedbackFromSubmitBtn {
  width: 150px;
  height: 50px;
  border-radius: 10%;
  border-color: rgb(84, 85, 94, 0.5);
  color: rgb(52, 53, 54);
}

.feedBackFormSubmit {
  margin: 20px;
  bottom: 50px;
}

.feedbackLinks a {
  font-size: 18px;
  color: #0e0a0a;
  display: block;
  transition: 0.3s;
}

.feedBackLinkItems {
}

.feedbackLinks a:hover {
  color: #c2bbbb;
}

.feedbackLinks {
  position: absolute;
  bottom: 15px;
  text-align: center;
  vertical-align: middle;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feedBackFormHeader {
  background-color: linear-gradient(90deg, #54555e, #9aa0dd);
  background-image: linear-gradient(90deg, rgba(36, 36, 37, 0.7), rgba(94, 97, 102, 0.4));
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat: initial;

  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  padding: 5px;
}
/*

border: 1px solid #070707;
 
 border-style: solid;
*/

.feedbackLabel {
  color: rgb(76, 77, 82);
}
